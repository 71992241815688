<template>
  <div id="page-profile-users">

    <b-card>
      <v-tabs background-color="white" color="accent-4" left>
        <v-tab>{{ $t('USER.MY_INFORMATION') }}</v-tab>
        <v-tab>{{ $t('USER.NOTIFICATIONS') }}</v-tab>

        <v-tab-item>

          <!-- todo add user info -->
          <UserUpdatePasswordModal
            ref="update-password-modal"
            :is_me="true"
            :user="user" />

          <form @submit.prevent="submit_form" class="mt-8">

            <b-row>
              <b-col md="6" sm="12">

                <b-form-group :label="$t('USER.EMAIL')">
                  <b-form-input v-model="form.email" type="text" :placeholder="$t('USER.EMAIL')"></b-form-input>

                </b-form-group>

              </b-col>

            </b-row>

            <b-row class="mb-8">
              <b-col md="6">
                <b-button @click="open_update_password" variant="primary">{{ $t('USER.UPDATE_PASSWORD') }}</b-button>
              </b-col>
            </b-row>


            <b-row>
              <b-col md="6" sm="12">
                <b-form-group :label="$t('USER.FIRSTNAME')">
                  <b-form-input v-model="form.firstname" type="text"
                    :placeholder="$t('USER.FIRSTNAME')"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6" sm="12">
                <b-form-group :label="$t('USER.LASTNAME')">
                  <b-form-input v-model="form.lastname" type="text"
                    :placeholder="$t('USER.LASTNAME')"></b-form-input>
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>

              <b-col md="6" sm="12">
                <b-form-group :label="$t('USER.PERSONNR')">
                  <b-form-input v-model="form.in_personnr" type="text"
                    :placeholder="$t('USER.PERSONNR')"></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6" sm="12">
                <b-form-group :label="$t('USER.PHONE')">
                  <b-form-input v-model="form.phone" type="text" :placeholder="$t('USER.PHONE')"></b-form-input>
                </b-form-group>
              </b-col>

            </b-row>

            <RightSaveButton ref="saveButton" :text="$t('COMMON.SAVE')" @clicked="save_clicked" />

          </form>


        </v-tab-item>

        <v-tab-item>
          <!-- todo add notifications -->

          <div class="mt-8">
            <b-form-group class="col-lg-6 col-sm-12" id="group-email">

              <div class="d-flex align-items-center mb-4">
                <label class="checkbox checkbox-outline checkbox-success">
                  <input type="checkbox" name="notif_email" v-model="form.notif_email" />
                  <span></span>
                </label>
                <span class="ml-3">{{ $t('USER.NOTIF_EMAIL') }}</span>
              </div>

            </b-form-group>

            <b-form-group class="col-lg-6 col-sm-12" id="group-email">

              <div class="d-flex align-items-center mb-4">
                <label class="checkbox checkbox-outline checkbox-success">
                  <input type="checkbox" name="notif_sms" v-model="form.notif_sms" />
                  <span></span>
                </label>
                <span class="ml-3">{{ $t('USER.NOTIF_SMS') }}</span>
              </div>

            </b-form-group>

            <RightSaveButton ref="saveButton" :text="$t('COMMON.SAVE')" @clicked="save_clicked" />
          </div>

        </v-tab-item>
      </v-tabs>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import UserUpdatePasswordModal from '@/view/pages/ml/users/components/UserUpdatePasswordModal.vue';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'user-profile',
  components: {
    UserUpdatePasswordModal,
    RightSaveButton
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'periods', 'companies', 'user']),
  },
  async mounted() {
    this.form = { ...this.user }
  },
  data() {
    return {


      form: {
        email: '',
        firstname: '',
        lastname: '',
        public_sex: 'U',
        sex: 'U'
      },

      genders: [
        { value: 'M', text: this.$t('COMMON.MAN') },
        { value: 'F', text: this.$t('COMMON.WOMAN') },
        { value: 'O', text: this.$t('COMMON.OTHER') },
        { value: 'U', text: this.$t('COMMON.UNKNOWN') }
      ],

    };
  },
  watch: {
    user: {
      deep: true,
      handler(val) {
        this.form = { ...this.user }
      }
    },
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {

      }
    },
  },
  methods: {
    open_update_password() {
      this.$refs['update-password-modal'].show();
    },
    async save_clicked() {

      await this.put_user(this.form);

      this.$refs['saveButton'].stop();
    },

    async put_user(form) {
      try {
        const res = await axios.put(`/access/user/me`, form);

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('USER.UPDATED'));
          return;
        }
      }
      catch (err) {
        console.error('put_user error', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('USER.UNABLE_UPDATE'));
    }
  }
};
</script>
